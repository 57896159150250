.homelogged--competition {
    background-image: url('../../../images/konkurs_compress.webp');
}

.homelogged__leftpanel--competition {
    background-color: #fec525;
    min-height: 770px;
}

.homelogged__panel--competition,
.homelogged__wrapper_bottom--competition {
    @media screen and (max-width: 768px) {
        background-color: #fec525;
    }
}

.competition__wrapper h1 {
    font-size: 2.5rem;
    @media screen and (max-width: 768px) {
        font-size: 2rem;
    }
}

.homelogged__wrapper_bottom--competition h1,
.homelogged__wrapper_bottom--competition p {
    line-height: 1.5;
}

.homelogged__wrapper_bottom--competition {
    gap: 2rem;
    max-width: 400px;
    @media screen and (max-width: 768px) {
        max-width: 100%;
    }
}