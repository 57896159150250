.trip {
    width: 100%;
}

.trip__inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 8rem;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
       padding-bottom: 0;
    }
}

.trip__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

.trip__column_img1 {
    display: block;
    width: 100%;
}

.trip__column_container {
    position: relative;
    display: inline-block;
    margin: 20px;
}

.trip__column_frame::after,
.trip__column_frame::before {
    content: '';
    position: absolute;
    border: 10px solid #FFCC00; /* Adjust color and size as needed */
    z-index: -1; /* To place the frame behind the image */
}

.trip__column_frame::before {
    top: -10px;
    left: -10px;
    right: 100px;
    height: calc(100% + 10px);
    border-right: none;
    border-bottom: none;
}

.trip__column_frame::after {
    bottom: -10px;
    left: -10px;
    right: 100px;
    height: 10px;
    border-top: none;
    border-right: none;
}

.trip__wrapper_img {
    width: 100%;
    @media screen and (max-width: 1024px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.trip__column_img2 {
    width: 200px;
    @media screen and (max-width: 768px) {
        width: 130px;
    }
}

.trip__title, .trip__description {
    line-height: 1.5;
}

.trip__column {
    display: flex;
    justify-content: center;
    align-items: center;
}

.trip__column2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media screen and (max-width: 768px) {
        gap: 2rem;
    }
}

.trip__button {
    background-color: #fff;
    border-radius: 27px;
    border: none;
    border: 2px solid #32bef0;
    color: #000;
    padding: .5rem 1rem;
    font-weight: bold;
    font-size: 0.8rem;
    cursor: pointer;
    font-family: 'termina';
    width: 250px;
    transition: all .3s ease;
    @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        padding: 1rem 1rem;
        width: 100%;
    }
}

.trip__button:hover {
    background-color: #32bef0;
}