.competition-rules {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.competition-rules__inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 8rem;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
    }
    @media screen and (max-width: 768px) {
        padding-top: 10rem;
     }
}

.competition-rules__wrapper {
    max-width: 500px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    @media screen and (max-width: 1024px) {
        max-width: 100%;
    }
}

.competition-rules__wrapper p {
    font-size: 1.1rem;
    line-height: 1.5;
    font-family: 'aktiv-grotesk';
}

.competition-rules__button {
    max-width: 280px;
    width: 100%;
    padding: 0.5rem 2rem;
    background-color: #fff;
    border-radius: 27px;
    font-weight: bold;
    border: none;
    border: 2px solid #32bef0;
    outline: none;
    cursor: pointer;
    transition: all .3s ease;
    font-family: 'termina';
    color: #000;
}

.competition-rules__button:hover {
    background-color: #32bef0;
}

.competition-rules .blue-text {
    color: #32bef0;
    font-weight: bold;
}

.competition-rules__image_bg {
    right: 5%;
}

.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #32bef0; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.competition-rules__file-uploaded {
    max-width: 300px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding: 1rem;
    background-color: #DFE8EB;
    font-size: .9rem;
}