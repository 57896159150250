.awards {
    position: relative;
    width: 100%;
}

.awards__inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 8rem;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
       padding-bottom: 0;
    }
}

.awards__title {
    font-size: 2rem;
}

.awards__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.75rem;
    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
        gap: 4rem;
    }
}

.awards__image {
    @media screen and (max-width: 1024px) {
        order: -1;
    }
}

.awards__image img {
    max-width: 100%;
}

.awards__wrapper2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 4rem;
    }
}

.awards__points {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.awards__container_title {
    font-size: 1.1rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.awards__container_subtitle {
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    font-family: 'aktiv-grotesk';
}

.awards__container_button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border: 2px solid #32bef0;
    background-color: #fff;
    color: #000;
    border-radius: 27px;
    padding: 0.75rem 2rem;
    font-weight: bold;
    font-size: 0.9rem;
    font-family: 'termina';
    cursor: pointer;
    transition: .3s ease;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.awards__container_button:hover {
    background-color: #32bef0;
}