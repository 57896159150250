.extra-points {
    width: 100%;
}

.extra-points__inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 8rem;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
       padding-bottom: 0;
    }
}

.extra-points__container {
    background-color: #32bef0;
    width: 100%;
    padding: 2rem 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    @media screen and (max-width: 768px) {
        padding: 2rem 2rem;
    }
}

.extra-points__container h2 {
    font-size: 2rem;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 1.1rem;
    }
}

.extra-points__container p {
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 0.8  rem;
    }
}

.extra-points__container button {
    background-color: #fff;
    border-radius: 27px;
    border: none;
    color: #000;
    padding: 1rem 6rem;
    font-weight: bold;
    font-size: 0.8rem;
    cursor: pointer;
    font-family: 'termina';
    @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        padding: 1rem 1rem;
        width: 100%;
    }
}