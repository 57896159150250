.homelogged {
    background-image: url('../../../images/baner_main_baby yetico.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100svh;
    min-height: 700px;
}

.homelogged__leftpanel {
    position: absolute;
    top: 0;
    left: 0;
    width: 645px;
    min-width: 550px;
    height: 100svh;
    min-height: 700px;
    background-color: #32bef0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    display: flex;
    align-items: center;
    padding-left: 8rem;
    @media screen and (max-width: 768px) {
        width: 100%;
        min-width: 100%;
        border-radius: 0;
        padding-left: 0;
    }
}

.homelogged__panel, .homelogged__wrapper_bottom {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    // max-width: 320px;
    height: 100%;
    @media screen and (max-width: 768px) {
        max-width: 100%;
        justify-content: flex-start;
        gap: 0;
        background-color: #32bef0;
    }
}

.homelogged__wrapper_bottom {
    @media screen and (max-width: 768px) {
        padding: 1.5rem;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

.homelogged__background--mobile {
    width: 100%;
    @media screen and (min-width: 769px) {
        display: none;
    }
}

.homelogged__text {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.homelogged__title {
    font-size: 2.8rem;
    color: #000;
    // @media screen and (max-width: 768px) {
    //     display: none;
    // }
}

.homelogged__subtitle--bold {
    font-size: 1.1rem;
    font-weight: bold;
    // @media screen and (max-width: 768px) {
    //     display: none;
    // }
}

.homelogged__subtitle {
    font-size: .75rem;
    text-transform: uppercase;
    padding-top: 0.75rem;
    @media screen and (max-width: 768px) {
        // font-weight: bold;
        font-size: .9rem;
        line-height: 1.1;
        max-width: 350px;
        width: 100%;
    }
}

.homelogged__text2 {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    @media screen and (max-width: 768px) {
        gap: 1rem;
    }
}

.homelogged__text2-2 {
    font-size: 1.1rem;
    line-height: 1.5;
}

.homelogged__button2 {
    width: 100%;
    max-width: 350px;
    padding: 1rem;
    background-color: #fff;
    border-radius: 25px;
    border: none;
    font-weight: bold;
    font-size: 0.9rem;
    font-family: 'termina';
    cursor: pointer;
    transition: all 0.3s ease;
    color: #000;
}

.homelogged__button2:hover {
    background-color: #fec525;
}

.homelogged__scroll {
    position: absolute;
    bottom: 20px;
    left: 50%;
    border: none;
    background: none;
    transform: translateX(-75%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    cursor: pointer;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.homelogged__scroll--icon {
    font-family: 'termina';
    width: 18px;
    animation: mouseIconAnimation 1s infinite;
}

.homelogged__scroll--text {
    color: #000;
}

@keyframes mouseIconAnimation {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(5px);
    }
    100% {
        transform: translateY(0);
    }
}