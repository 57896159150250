.quizzes {
    width: 100%;
}

.quizzes__inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 8rem;
    padding-top: 8rem;
    padding-right: 0;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
    }
}

.quizzes__title {
    max-width: 114px;
    width: 100%;
    font-size: 2rem;
    line-height: 1.5;
}

.quizzes__title::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #000;
    margin-top: 1rem;
}

.quizzes__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-bottom: 2rem;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        overflow-y: hidden;
        gap: 4rem;
        padding-bottom: 0;
    }
}

.quizzes__wrapper::-webkit-scrollbar {
    height: 10px; /* Wysokość poziomego paska przewijania */
    background-color: #e0e0e0; /* Kolor tła paska przewijania */
    border-radius: 10px; /* Zaokrąglone końce */
    @media screen and (max-width: 768px) {
        height: 0;
    }
}

.quizzes__wrapper::-webkit-scrollbar-thumb {
    background-color: #a0a0a0; /* Kolor suwaka */
    border-radius: 10px; /* Zaokrąglone końce suwaka */
    border: 3px solid #e0e0e0; /* Obramowanie suwaka */
}

.quizzes__wrapper::-webkit-scrollbar-track {
    background-color: #e0e0e0; /* Kolor tła toru */
    border-radius: 10px; /* Zaokrąglone końce toru */
}

.quizzes__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.quizzes__square {
    position: relative;
    background-color: #EFEFEF;
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    padding-top: 3rem;
}

.quizzes__container_win_text {
    color: #000;
    font-weight: bold;
}

.quizzes__container_name {
    margin-bottom: 2rem;
}

.quizzes__container_quiz_title {
    margin-bottom: 2rem;
    font-weight: 600;
    color: #FB5126;
    font-size: 1.4rem;
}

.quizzes__flower_img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 112px;
}

.quizzes__reward_img {
    width: 170px;
}

.quizzes__reward_img--aeno {
    width: 208px;
}

.quizzes__availability {
    color: #32BEF0;
    font-weight: 500;
    font-family: "aktiv-grotesk";
}

.quizzes__inaccessible {
    font-weight: 500;
    font-family: "aktiv-grotesk";
    color: #FB5126;
}

.quizzes__button {
    background-color: #fff;
    border: 2px solid #32BEF0;
    padding: .75rem 3rem;
    font-weight: bold;
    border-radius: 27px;
    font-family: "termina";
    cursor: pointer;
    transition: all .3s ease;
    color: #000;
}

.quizzes__button:disabled {
    color: #999999;
}

.quizzes__button:hover {
    background-color: #32BEF0;
}

.quizzes__container_points_award {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    font-size: 4rem;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 0;
}

.quizzes__container_plus {
    font-size: 1.5rem;
}

.quizzes__container_extra_award {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.3;
}

.quizzes__subtitle {
    line-height: 1.5;
    max-width: 730px;
    width: 100%;
}