*, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 1;
}

html, body {
    font-family: 'termina';
    background-color: #fff;
    background: #fff;
    height: 100%;
    width: 100%;
    color: black;
    margin: 0;
    padding: 0;
    position: relative;
}

html {
    -webkit-text-size-adjust: 100%; /* Zapobieganie zmianie rozmiaru tekstu */
}

input, textarea, select {
    touch-action: manipulation; /* Zapobieganie niepotrzebnym akcjom dotykowym */
}

@media (prefers-color-scheme: dark) {
    html, body {
        background-color: white;
        color: black;
    }
}