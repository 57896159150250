.section-rules {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
    @media screen and (max-width: 1024px) {
        padding-bottom: 3rem;
    }
}

.section-rules__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    gap: 2rem;
    padding: 6rem 8rem;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
       padding-bottom: 0;
       
    }
}

.section-rules__title {
    font-size: 2rem;
}

.section-rules__subtitle {
    font-size: 1rem;
}

.section-rules__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.75rem;
    @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
    }
}

.section-rules__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #DFE8EB;
    padding: 2rem 4rem;
    @media screen and (max-width: 1300px) {
        padding: 2rem 1rem;
    }
}

.section-rules__container h2 {
    font-size: 1.1rem;
    font-weight: 400;
}

.section-rules__container p {
    font-size: 0.9rem;
    line-height: 1.5;
    font-family: 'aktiv-grotesk';
}

.section-rules__container .line {
    width: 100%;
    height: 1px;
    background-color: #000;
}

.section-rules__container .text2 {
    font-style: italic;
    font-size: 0.65rem;
}

.container--last {
    background-color: #fff;
}

.section-rules__button {
    max-width: 250px;
    width: 100%;
    background-color: #fff;
    padding: 1rem;
    border-radius: 25px;
    color: #000;
    font-size: 0.8rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-family: 'termina';
    transition: .3s ease;
    @media screen and (max-width: 1024px) {
        width: 100%;
        max-width: 100%;
    }
}

.section-rules__button:hover {
    background-color: #fec525;
}

.section-rules__image-bg {
    position: absolute;
    right: -8%;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    max-width: 700px;
    @media screen and (max-width: 1024px) {
        display: none;
    }
}

.section-rules__image-bg--mobile {
    display: none;
    padding: 5rem;
    @media screen and (max-width: 1024px) {
       display: block;
       max-width: 400px;
       width: 100%;
       padding-top: 0;
       padding-bottom: 0;
    }
}

.rules-space-div {
    @media screen and (max-width: 1024px) {
        display: none;
    }
}