.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
    background-color: #fff;
    padding: 1rem;
    @media screen and (max-width: 1300px) {
        display: none;
    }
}

.header__inner {
   padding-left: 8rem;
   padding-right: 8rem;
   display: flex;
   flex-direction: row;
   gap: 1rem;
   justify-content: space-between;
}

.header__logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__nav_desktop {
    width: 100%;
}

.header__nav_desktop ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    list-style-type: none;
    justify-content: space-evenly;
    width: 100%;
}

.header__nav_desktop ul li a {
    text-decoration: none;
    color: #000;
    font-size: 0.8rem;
    text-align: center;
}

.header__user {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.header__user_wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 0.8rem;
    font-family: 'termina';
}

.header__user_name {
    font-size: 0.8rem;
    font-weight: bold;
    white-space: nowrap;
}

.header__user_logout {
    font-size: 0.8rem;
    color: #000;
    border: none;
    background: none;
    font-family: 'termina';
    cursor: pointer;
}

.header__nav_center {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.4;
}

.header-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99999;
    background-color: transparent;
    padding: 1rem;
    @media screen and (min-width: 1300px) {
        display: none;
    }
}

.header-mobile__inner {
    padding-left: 8rem;
    padding-right: 8rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

.header-mobile__hamburger {
    position: relative;
    border: none;
    background: none;
    width: 30px;
    height: 20px;
    cursor: pointer;
}

.hamburger__line:nth-child(1) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 3px;
    background-color: #000;
}

.hamburger__line:nth-child(2) {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 3px;
    background-color: #000;
}

.hamburger__line:nth-child(3) {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: #000;
}

.header__logo--mobile {
    @media screen and (max-width: 576px) {
        width: 100px;
    }
}

.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #272727;
    z-index: 99999;
    overflow: auto;
    padding-bottom: 1.5rem;
    transform: translateX(100%);
    transition: .2s linear;
    @media screen and (min-width: 1300px) {
        display: none;
    }
}

.mobile-menu__top {
    padding: 1rem 9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.header__logo--mobile {
    @media screen and (max-width: 576px) {
        width: 100px;
    }
}

.mobile-menu__close {
    position: relative;
    border: none;
    background: none;
    width: 30px;
    height: 20px;
    cursor: pointer;
}

.close__line:nth-child(1) {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 3px;
    background-color: #fff;
    transform: translate(-50%, -50%) rotate(45deg);
}

.close__line:nth-child(2) {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 3px;
    background-color: #fff;
    transform: translate(-50%, -50%) rotate(-45deg);
}

.mobile-menu__user {
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
    margin-top: 3rem;
}

.mobile-menu__points {
    text-align: center;
    color: #32bef0;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.75rem;
    margin-bottom: 3rem;
}

.mobile-menu__nav {
    width: 100%;
    display: flex;
    justify-content: center;
}

.mobile-menu__nav ul {
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
    gap: 2rem;
}

.mobile-menu__nav ul li a {
    display: flex;
    gap: 1rem;
    align-items: center;
    color: #fff;
    text-decoration: none;
    line-height: 1.5;
}

.mobile-menu__nav ul li a img {
    max-width: 30px;
    width: 100%;
}

.active-mobile-menu {
    transform: translateX(0);
}

.inverted-logo {
    filter: invert(1);
}