.footer {
    width: 100%;
    background-color: #DFE8EB;
}

.footer__inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 8rem;
    @media screen and (max-width: 1024px) {
       padding: 6rem 1rem;
    }
}

.footer__logo_wrapper {
    border-bottom: 1px solid black;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.footer__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 4rem;
    }
}

.footer__column1 {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.footer__link, .footer__copyrights, .footer__website, .footer__bottom_text {
    color: #000;
    text-decoration: none;
    font-size: 0.8rem;
    text-align: right;
    line-height: 1.5;
    font-family: 'aktiv-grotesk';
    @media screen and (max-width: 768px) {
        text-align: left !important;
    }
}

.footer__website {
    text-align: right;
}

.footer__column2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4rem;
    @media screen and (max-width: 768px) {
        align-items: flex-start;
    }
}

.footer__column_wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footer__breakline {
    @media screen and (min-width: 768px) {
        display: none;
    }
}